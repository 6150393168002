import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'

import vco from "v-click-outside"
import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from 'vue-sweetalert2';
import VueSlideBar from 'vue-slide-bar'
import Vuelidate from 'vuelidate'
import i18n from './i18n'
import store from '@/state/store'

import App from './App.vue'
// As a plugin
import VueMask from 'v-mask'
Vue.config.productionTip = false
import * as VueGoogleMaps from "vue2-google-maps";
import Lightbox from 'vue-easy-lightbox'
 
import VueQrcodeReader from "vue-qrcode-reader";

Vue.use(VueQrcodeReader);
// import axios from 'axios';
// axios.defaults.headers.common["current-url"] = window.location;

Vue.use(Lightbox)
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places"
  },
  installComponents: true
});

import VueProgressBar from 'vue-progressbar'

Vue.use(VueProgressBar, {
  color: '#772b8e',
  failedColor: 'red',
  thickness: '3px'
})

import appConfig from "@/app.config";
if (appConfig.debug == false) {
  console.log = function() {}
}
// import { initFirebaseBackend } from './helpers/firebase/authUtils';

// import { configureFakeBackend } from './helpers/fakebackend/fake-backend';
import firebase from 'firebase/app';
import 'firebase/auth';
const firebaseConfig = {
  apiKey: "AIzaSyBcDVC3OE_qAq38k-3vWSPRyxT22EEt3rs",
  authDomain: "blacksun-49e8b.firebaseapp.com",
  projectId: "blacksun-49e8b",
  storageBucket: "blacksun-49e8b.appspot.com",
  messagingSenderId: "866490704687",
  appId: "1:866490704687:web:749878ddc6c81dc4bcf7da",
  measurementId: "G-3392XBG7QD"
};
firebase.initializeApp(firebaseConfig);
// if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
//   initFirebaseBackend(firebaseConfig);
// } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
//   configureFakeBackend();
// }

import '@/assets/scss/app.scss'
 
Vue.component('VueSlideBar', VueSlideBar)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.component('apexchart', VueApexCharts)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);

Vue.use(require("vue-chartist"));
Vue.use(VueMask);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
