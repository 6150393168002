import { EncryptStorage } from 'encrypt-storage';
import appConfig from "@/app.config";
export const encryptStorage = new EncryptStorage('secret-key-value', {
  prefix: appConfig.prefix,
});
export default[
{
   path: '/',
   name: 'dashboard',
   meta: {
     authRequired: true,
   },
   component: () => import('../views/pages/app/dashboard')
 },
 {
  path: '/profile-setting',
  name: 'profile',
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/app/setting')
},
{
  path: '/reseller',
  name: 'resellers',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const accountType = data.account_type;
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "account_management");
    if (foundModule) {
      if (accountType !== 'merchant') {
        // Allow access to the route for admin accounts
        next();
      } else {
        // Redirect to a different route for non-admin accounts
        next('/');
      }
    }else{
      next('/');
    }
  },
  component: () => import('../views/pages/app/resellers')
},
{
  path: '/reseller/:id',
  name: 'resellers with reseller id',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const accountType = data.account_type;
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "account_management");
    if (foundModule) {
      if (accountType !== 'merchant') {
        // Allow access to the route for admin accounts
        next();
      } else {
        // Redirect to a different route for non-admin accounts
        next('/');
      }
    }else{
      next('/');
    }
  },
  component: () => import('../views/pages/app/resellers')
},
{
  path: '/merchants',
  name: 'merchants',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const accountType = data.account_type;
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "account_management");
    if (foundModule) {
      if (accountType !== 'merchant') {
        // Allow access to the route for admin accounts
        next();
      } else {
        // Redirect to a different route for non-admin accounts
        next('/');
      }
    }else{
      next('/');
    }
  },
  component: () => import('../views/pages/app/merchants')
},
{
  path: '/merchants/from-reseller=:id',
  name: 'merchants with reseller id',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const accountType = data.account_type;
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "account_management");
    if (foundModule) {
      if (accountType !== 'merchant') {
        // Allow access to the route for admin accounts
        next();
      } else {
        // Redirect to a different route for non-admin accounts
        next('/');
      }
    }else{
      next('/');
    }
  },
  component: () => import('../views/pages/app/merchants')
},
{
  path: '/payment-gateway',
  name: 'payment gateway',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const accountType = data.account_type;
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "administrator");
    if (foundModule) {
      // Allow access to the route for admin accounts
      if (accountType == 'admin') {
        next();
      } else {
        next('/');
      }
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/payment-gateway')
},
{
  path: '/admin-logs',
  name: 'admin logs',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const accountType = data.account_type;
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "administrator");
    if (foundModule) {
      // Allow access to the route for admin accounts
      if (accountType == 'admin') {
        next();
      } else {
        next('/');
      }
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/admin-logs.vue')
},
{
  path: '/transactions',
  name: 'payin',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "payin");
    if (foundModule) {
      next();
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/payin')
},
{
  path: '/transactions/processing-list',
  name: 'processing payin',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "payin");
    if (foundModule) {
      next();
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/processing_transactions')
},
{
  path: '/payouts/list',
  name: 'payout',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "payout");
    if (foundModule) {
      next();
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/payouts')
},
{
  path: '/payouts/pending-list',
  name: 'pending payouts',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "payout");
    if (foundModule) {
      next();
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/pending_payouts')
},
{
  path: '/contracts',
  name: 'contracts',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "my_contracts" || "account_management");
    if (foundModule) {
      next();
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/contracts')
},
{
  path: '/contract-acc-ledger',
  name: 'contract ledger',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "reports");
    if (foundModule) {
      next();
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/contract-ledger')
},
{
  //path: '/reseller/:resellerid/contract-acc-ledger/:id',
  path: '/contract-acc-ledger?rid=:resellerid&cid=:id',
  name: 'contract ledger with id',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "reports");
    if (foundModule) {
      next();
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/contract-ledger')
},
{
  path: '/contract-acc-ledger/:id',
  name: 'contract ledger merchant',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "reports");
    if (foundModule) {
      next();
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/contract-ledger')
},
{
  path: '/reseller-contracts',
  name: 'reseller contracts',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const accountType = data.account_type;
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "account_management");
    if (foundModule){
      if (accountType !== 'merchant') {
        // Allow access to the route for admin accounts
        next();
      } else {
        // Redirect to a different route for non-admin accounts
        next('/');
      }
    }else{
      next('/');
    }
  },
  component: () => import('../views/pages/app/reseller-contracts')
},
{
  path: '/reseller-contract-acc-ledger',
  name: 'reseller contract ledger',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const accountType = data.account_type;
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "reports");
    if (foundModule){
      if (accountType !== 'merchant') {
        // Allow access to the route for admin accounts
        next();
      } else {
        // Redirect to a different route for non-admin accounts
        next('/');
      }
    }else{
      next('/');
    }
  },
  component: () => import('../views/pages/app/reseller-contract-ledger')
},
{
  //path: '/reseller/:resellerid/reseller-contract-acc-ledger/:id',
  path: '/reseller-contract-acc-ledger?rid=:resellerid&cid=:id',
  name: 'contract ledger reseller with id',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const accountType = data.account_type;
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "reports");
    if (foundModule){
      if (accountType !== 'merchant') {
        // Allow access to the route for admin accounts
        next();
      } else {
        // Redirect to a different route for non-admin accounts
        next('/');
      }
    }else{
      next('/');
    }
  },
  component: () => import('../views/pages/app/reseller-contract-ledger')
},
{
  path: '/reseller-contract-acc-ledger/:id',
  name: 'contract ledger reseller',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const accountType = data.account_type;
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "reports");
    if (foundModule){
      if (accountType !== 'merchant') {
        // Allow access to the route for admin accounts
        next();
      } else {
        // Redirect to a different route for non-admin accounts
        next('/');
      }
    }else{
      next('/');
    }
  },
  component: () => import('../views/pages/app/reseller-contract-ledger')
},
{
  path: '/settlements/list',
  name: 'settlements',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "settlements");
    if (foundModule) {
      // Allow access to the route for admin accounts
      next();
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/settlements')
},
{
  path: '/settlements/pending-list',
  name: 'pending settlements',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "settlements");
    if (foundModule) {
      next();
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/pending_settlements')
},
{
  path: '/withdrawals/list',
  name: 'withdrawal',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const accountType = data.account_type;
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "withdrawal");
    if (foundModule) {
      if (accountType !== 'merchant') {
        next();
      } else {
        next('/');
      }
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/withdrawal')
},
{
  path: '/withdrawals/pending-list',
  name: 'pending withdrawal',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const accountType = data.account_type;
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "withdrawal");
    if (foundModule) {
      if (accountType !== 'merchant') {
        next();
      } else {
        next('/');
      }
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/pending_withdrawal')
},
{
  path: '/top-up/list',
  name: 'top up',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const accountType = data.account_type;
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "top_up");
    if (foundModule) {
      // Allow access to the route for admin accounts
      if (accountType == 'admin') {
        next();
      } else {
        next('/');
      }
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/topup')
},
{
  path: '/daily-transactions-report',
  name: 'daily transactions report',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const accountType = data.account_type;
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "reports");
    if (foundModule) {
      // Allow access to the route for admin accounts
      if (accountType == 'admin' || accountType == 'merchant') {
        next();
      } else {
        next('/');
      }
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/daily-transactions-report')
},
{
  path: '/daily-earning-report',
  name: 'daily earning report',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const accountType = data.account_type;
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "reports");
    if (foundModule) {
      // Allow access to the route for admin accounts
      if (accountType == 'admin' || accountType == 'reseller') {
        next();
      } else {
        next('/');
      }
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/daily-earning-report')
},

{
  path: '/reseller-earning-statement',
  name: 'reseller earning statement',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const accountType = data.account_type;
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "reports");
    if (foundModule) {
      // Allow access to the route for admin accounts
      if (accountType == 'admin' || accountType == 'reseller') {
        next();
      } else {
        next('/');
      }
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/reseller-earning-statement')
},

{
  path: '/reseller-balance-settlement',
  name: 'reseller balance settlement',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const accountType = data.account_type;
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "reports");
    if (foundModule) {
      // Allow access to the route for admin accounts
      if (accountType == 'admin' || accountType == 'reseller') {
        next();
      } else {
        next('/');
      }
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/reseller-balance-settlement')
},
{
  path: '/merchant-balance-settlement',
  name: 'merchant balance settlement',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const accountType = data.account_type;
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "reports");
    if (foundModule) {
      // Allow access to the route for admin accounts
      if (accountType == 'admin' || accountType == 'merchant') {
        next();
      } else {
        next('/');
      }
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/merchant-balance-settlement')
},

{
  path: '/sub-accounts',
  name: 'sub accounts',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "sub_accounts");
    if (foundModule) {
      // Allow access to the route for admin accounts
      next();
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/user-management')
},

{
  path: '/merchant-settings',
  name: 'merchant settings',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const accountType = data.account_type;
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "technical");
    if (foundModule) {
      // Allow access to the route for admin accounts
      if (accountType == 'merchant') {
        next();
      } else {
        next('/');
      }
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/merchant-settings')
},
{
  path: '/api-documentation',
  name: 'api documentation',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "technical");
    if (foundModule) {
      // Allow access to the route for admin accounts
      next();
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/api-documentation')
},
{
  path: '/technical-support',
  name: 'technical support',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "technical");
    if (foundModule) {
      // Allow access to the route for admin accounts
      next();
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/technical-support')
},
{
  path: '/technical-information',
  name: 'technical information',
  meta: {
    authRequired: true,
  },
  beforeEnter: (to, from, next) => {
    var data = encryptStorage.getItem('userdata')|| {}
    const modules = data.modules;
    const foundModule = modules.find(item => item.name === "technical");
    if (foundModule) {
      // Allow access to the route for admin accounts
      next();
    } else {
      // Redirect to a different route for non-admin accounts
      next('/');
    }
  },
  component: () => import('../views/pages/app/technical-information')
},

{
  path: '/support-on-telegram',
  name: 'telegram',
  meta: {
  },
  beforeEnter(){
    var data = encryptStorage.getItem('userdata')|| {}
    const telegram_id = data.telegram_id;
    // Create an anchor element
    const anchor = document.createElement('a');
    anchor.href = `https://t.me/${telegram_id}`;
    anchor.target = '_blank';
    anchor.click();
  }
},
{
  path: '/marketing-materials',
  name: 'marketing-materials',
  meta: {
  },
  beforeEnter(){
    const anchor = document.createElement('a');
    anchor.href = `https://drive.google.com/drive/folders/18WeozacBFqiWPkgS8b2iNb_XNOZWon8Y?usp=sharing`;
    anchor.target = '_blank';
    anchor.click();
  }
},
{
  path: '/starter',
  name: 'starter',
  meta: {
    authRequired: true,
  },
  component: () => import('../views/pages/app/starter')
},
 {
   path: '/login',
   name: 'login',
   meta: {
     guest: true,
   },
   component: () => import('../views/pages/app/login')
 },
 {
  path: '/otp',
  name: '2-step-verification',
  meta: {
    guest: true,
  },
  component: () => import('../views/pages/app/otp')
},
 {
   path: '/logout',
   name: 'logout',
   meta: {
     authRequired: true,
   },
   component: () => import('../views/pages/app/logout')
 },
 

 // {
 //   path: '/calendar',
 //   name: 'calendar',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/calendar/index')
 // },
 // {
 //   path: '/chat',
 //   name: 'chat',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/chat/index')
 // },
 // {
 //   path: '/ecommerce/products',
 //   name: 'Products',
 //   meta: {
 //     authRequired: true
 //   },
 //   component: () => import('../views/pages/ecommerce/products')
 // },
 // {
 //   path: '/ecommerce/product-detail/:id',
 //   name: 'Product Detail',
 //   meta: {
 //     authRequired: true
 //   },
 //   component: () => import('../views/pages/ecommerce/product-detail')
 // },
 // {
 //   path: '/ecommerce/orders',
 //   name: 'Orders',
 //   meta: {
 //     authRequired: true
 //   },
 //   component: () => import('../views/pages/ecommerce/orders')
 // },
 // {
 //   path: '/ecommerce/customers',
 //   name: 'Customers',
 //   meta: {
 //     authRequired: true
 //   },
 //   component: () => import('../views/pages/ecommerce/customers')
 // },
 // {
 //   path: '/ecommerce/cart',
 //   name: 'Cart',
 //   meta: {
 //     authRequired: true
 //   },
 //   component: () => import('../views/pages/ecommerce/cart')
 // },
 // {
 //   path: '/ecommerce/checkout',
 //   name: 'Checkout',
 //   meta: {
 //     authRequired: true
 //   },
 //   component: () => import('../views/pages/ecommerce/checkout')
 // },
 // {
 //   path: '/ecommerce/shops',
 //   name: 'Shops',
 //   meta: {
 //     authRequired: true
 //   },
 //   component: () => import('../views/pages/ecommerce/shops')
 // },
 // {
 //   path: '/ecommerce/add-product',
 //   name: 'Add Product',
 //   meta: {
 //     authRequired: true
 //   },
 //   component: () => import('../views/pages/ecommerce/add-product')
 // },
 // {
 //   path: '/email/inbox',
 //   name: 'Inbox',
 //   meta: {
 //     authRequired: true
 //   },
 //   component: () => import('../views/pages/email/inbox')
 // },
 // {
 //   path: '/email/reademail/:id',
 //   name: 'Read Email',
 //   meta: {
 //     authRequired: true
 //   },
 //   component: () => import('../views/pages/email/reademail')
 // },
 // {
 //   path: '/invoices/detail',
 //   name: 'Invoice Detail',
 //   meta: {
 //     authRequired: true
 //   },
 //   component: () => import('../views/pages/invoices/detail')
 // },
 // {
 //   path: '/invoices/list',
 //   name: 'Invoice List',
 //   meta: {
 //     authRequired: true
 //   },
 //   component: () => import('../views/pages/invoices/list')
 // },
 // {
 //   path: '/contacts/grid',
 //   name: 'User Grid',
 //   meta: {
 //     authRequired: true
 //   },
 //   component: () => import('../views/pages/contacts/grid')
 // },
 // {
 //   path: '/contacts/list',
 //   name: 'User List',
 //   meta: {
 //     authRequired: true
 //   },
 //   component: () => import('../views/pages/contacts/list')
 // },
 // {
 //   path: '/contacts/profile',
 //   name: 'Profile',
 //   meta: {
 //     authRequired: true
 //   },
 //   component: () => import('../views/pages/contacts/profile')
 // },
 // {
 //   path: '/utility/starter',
 //   name: 'Starter-page',
 //   meta: {
 //     authRequired: true
 //   },
 //   component: () => import('../views/pages/utility/starter')
 // },
 // {
 //   path: '/utility/maintenance',
 //   name: 'maintenance',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/utility/maintenance')
 // },
 // {
 //   path: '/utility/comingsoon',
 //   name: 'comingsoon',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/utility/comingsoon')
 // },
 // {
 //   path: '/utility/timeline',
 //   name: 'timeline',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/utility/timeline')
 // },
 // {
 //   path: '/utility/faqs',
 //   name: 'faqs',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/utility/faqs')
 // },
 // {
 //   path: '/utility/pricing',
 //   name: 'pricing',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/utility/pricing')
 // },
 // {
 //   path: '/utility/404',
 //   name: 'error-404',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/utility/404')
 // },
 // {
 //   path: '/utility/500',
 //   name: 'error-500',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/utility/500')
 // },
 // {
 //   path: '/form/elements',
 //   name: 'elements',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/form/elements')
 // },
 // {
 //   path: '/form/validation',
 //   name: 'validation',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/form/validation')
 // },
 // {
 //   path: '/form/advanced',
 //   name: 'form-advanced',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/form/advanced')
 // },
 // {
 //   path: '/form/editor',
 //   name: 'editor',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/form/editor')
 // },
 // {
 //   path: '/form/upload',
 //   name: 'upload',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/form/upload')
 // },
 // {
 //   path: '/form/repeater',
 //   name: 'repeater',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/form/repeater')
 // },
 // {
 //   path: '/form/wizard',
 //   name: 'wizard',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/form/wizard')
 // },
 // {
 //   path: '/form/mask',
 //   name: 'mask',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/form/mask')
 // },
 // {
 //   path: '/tables/basic',
 //   name: 'basic-table',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/tables/basic')
 // },
 // {
 //   path: '/tables/advanced',
 //   name: 'advanced',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/tables/advanced')
 // },
 // {
 //   path: '/charts/apex',
 //   name: 'apex',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/charts/apex/index')
 // },
 // {
 //   path: '/charts/chartist',
 //   name: 'chartist',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/charts/chartist/index')
 // },
 // {
 //   path: '/charts/chartjs',
 //   name: 'chartjs',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/charts/chartjs/index')
 // },
 // {
 //   path: '/charts/echart',
 //   name: 'echart',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/charts/echart/index')
 // },
 // {
 //   path: '/icons/unicons',
 //   name: 'unicons',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/icons/unicons')
 // },
 // {
 //   path: '/icons/boxicons',
 //   name: 'boxicons',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/icons/boxicons')
 // },
 // {
 //   path: '/icons/materialdesign',
 //   name: 'materialdesign',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/icons/materialdesign')
 // },
 // {
 //   path: '/icons/dripicons',
 //   name: 'dripicons',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/icons/dripicons')
 // },
 // {
 //   path: '/icons/fontawesome',
 //   name: 'fontawesome',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/icons/fontawesome')
 // },
 // {
 //   path: '/maps/google',
 //   name: 'google',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/maps/google')
 // },
 // {
 //   path: '/maps/leaflet',
 //   name: 'leaflet',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/maps/leaflet')
 // },
 // {
 //   path: '/ui/alerts',
 //   name: 'alerts',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/ui/alerts')
 // },
 // {
 //   path: '/ui/buttons',
 //   name: 'buttons',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/ui/buttons')
 // },
 // {
 //   path: '/ui/cards',
 //   name: 'cards',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/ui/cards')
 // }, {
 //   path: '/ui/carousel',
 //   name: 'carousel',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/ui/carousel')
 // }, {
 //   path: '/ui/dropdown',
 //   name: 'dropdown',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/ui/dropdown')
 // }, {
 //   path: '/ui/grid',
 //   name: 'grid',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/ui/grid')
 // }, {
 //   path: '/ui/images',
 //   name: 'images',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/ui/images')
 // }, {
 //   path: '/ui/lightbox',
 //   name: 'lightbox',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/ui/lightbox')
 // },
 // {
 //   path: '/ui/modals',
 //   name: 'modals',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/ui/modals')
 // },
 // {
 //   path: '/ui/rangeslider',
 //   name: 'rangeslider',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/ui/rangeslider')
 // },
 // {
 //   path: '/ui/progressbar',
 //   name: 'progressbar',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/ui/progressbar')
 // },
 // {
 //   path: '/ui/placeholder',
 //   name: 'placeholder',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/ui/placeholder')
 // },
 // {
 //   path: '/ui/sweet-alert',
 //   name: 'sweet-alert',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/ui/sweet-alert')
 // },

 // {
 //   path: '/ui/tabs-accordions',
 //   name: 'tabs-accordions',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/ui/tabs-accordions')
 // },
 // {
 //   path: '/ui/typography',
 //   name: 'typography',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/ui/typography')
 // },

 // {
 //   path: '/ui/video',
 //   name: 'video',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/ui/video')
 // },
 // {
 //   path: '/ui/general',
 //   name: 'general',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/ui/general')
 // },
 // {
 //   path: '/ui/colors',
 //   name: 'colors',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/ui/colors')
 // },
 // {
 //   path: '/ui/rating',
 //   name: 'rating',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/ui/rating')
 // },
 // {
 //   path: '/auth/login-1',
 //   name: 'login-1',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/auth/login-1')
 // },
 // {
 //   path: '/auth/register-1',
 //   name: 'register-1',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/auth/register-1')
 // },{
 //   path: '/auth/lock-screen',
 //   name: 'lock-screen',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/auth/lock-screen')
 // },{
 //   path: '/auth/recoverpwd',
 //   name: 'recoverpwd',
 //   meta: {
 //     authRequired: true,
 //   },
 //   component: () => import('../views/pages/auth/recoverpwd')
 // },
 {
   path: '/reset',
   name: 'Reset',
   meta: {
   },
   beforeEnter(){
     localStorage.clear()
     sessionStorage.clear()
     window.location.href = '/'
   }
 },
 {
  path: '/skype',
  name: 'skype',
  meta: {
  },
  beforeEnter(){
    window.location.href = 'skype:iamaibot@hotmail.com?chat&message='
  }
},

 {
     path: '/404',
     name: 'Error 404',
     meta: {
         authRequired: true,
     },
     component: () =>
         import ('../views/pages/app/404')
 },
 {
     path: "/:pathMatch(.*)*",
     redirect: "/404",
 }
 
]
